import React from 'react';
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../hooks/privateRoute";
import Dashboard from '../pages/dashboard';
import WalletAddress from '../pages/walletAddress';
import BiocharNft from '../pages/biocharNft';
import About from "../pages/about";

const RoutesPage = () => {

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/wallet-address" element={<WalletAddress />} />
        <Route path="/biocharNft" element={<BiocharNft />} />
        <Route path="/about" element={<About />} />
      </Route>
    </Routes>
  )
}

export default RoutesPage;