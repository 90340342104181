import "../../../assets/css/sidebar.css";
import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../../assets/icons/energy-icon.svg";
import ActiveIcon from "../../../assets/icons/energy-icon-active.svg";

const SideBar = () => {
  const page = window.location.pathname;

  const redirectInTransactionHistory = () => {
    window.open(process.env.REACT_APP_TRANSACTION_URL, "_blank", "noreferrer");
  }

  return (
    <div className="side-bar-main text-center h-100">
      <div className="card h-100">
        <div className="card-body p-0">
          <nav className="nav flex-column">
            <NavLink className="nav-link font-5F738C" activeclassname="active" to="/dashboard">
              <i className="fal fa-signal-alt-2 font-30"></i>
              <br />
              <small className="font-12">Dasboard</small>
            </NavLink>

            <NavLink className="nav-link font-5F738C" activeclassname="active" to="/wallet-address">
              <i className="fal fa-wallet font-30"></i>
              <br />
              <small className="font-12">Wallet</small>
            </NavLink>

            <NavLink className="nav-link font-5F738C" activeclassname="active" to="/biocharnft">
              {page === "/biocharnft" ? (
                <img src={ActiveIcon} height={45} alt="" />
              ) : (
                <img src={Icon} height={45} alt="" />
              )}
              <br />
              <small className="font-12">Biochar NFT</small>
            </NavLink>

            <div className="nav-link font-5F738C mt-4 mb-3" onClick={() => redirectInTransactionHistory()}>
              <i className="fal fa-sitemap font-30"></i>
              <br />
              <small className="font-12">Transaction History</small>
            </div>

            <NavLink className="nav-link font-5F738C" activeclassname="active" to="/about">
              <i className="fal fa-info-circle font-30"></i>
              <br />
              <small className="font-12">About</small>
            </NavLink>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
